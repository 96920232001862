<template>
  <div class="about-us" oncontextmenu="return false" onselectstart="return false">
    <!--顶部-->
    <header-nav :current="current"></header-nav>
    <div class="content" id="pdfDom">
      <div class="table" :class="{'pdfheight': isPrint}">
        <h1 class="title">
          <b>【自闭症类群障碍检核表（汉语版）】<br>
            Checklist for Autism Spectrum Disorder-Chinese Version（CASD-C）<br><br>
            个人报告</b>
        </h1>
        <div class="author">
          <p><i>Susan Dickerson Mayes, Ph. D. </i>编制</p>
          <p>赵家琛、吴怡慧、曹光文、陈明终 修订</p>
        </div>
        <p class="title3 tx-l">量表介绍</p>
        <table border="1" cellspacing="1" cellpadding="0">
          <tr>
            <th>
              <p class="tips tx-idt2" style="word-break:break-all;">本检核表是由台湾学者赵家琛、吴怡慧、曹光文、陈明终修订自S. D. Mayes所编制的Checklist
                for Autism Spectrum
                Disorder（CASD），采自闭症光谱的概念设计，与DSM-5方向一致，亦符合DSM-5的诊断准则。测验内容检核自闭症类群障碍的六大行为特征，包含社会互动问题、持续重复行为、体觉困扰、非典型沟通及发展、情绪困扰、注意力与安全问题。可快速、有效鉴别自闭症类群障碍儿童。</p>
            </th>
          </tr>
        </table>
        <div class="head-title">
          <p class="title3 tx-l">基本资料</p>
          <p class="title3 tx-l">个案编号：{{baseInfo.sn}}</p>
        </div>
        <table border="1" cellspacing="1" cellpadding="0">
          <tr>
            <th>孩子姓名</th>
            <td>
              <div class="input">
                <input type="text" readonly v-model="baseInfo.child_name">
              </div>
            </td>
            <th>评量日期</th>
            <td>
              <div class="input">
                <input type="text" readonly v-model="baseInfo.exam_time">
              </div>
            </td>
          </tr>
          <tr>
            <th>孩子性别</th>
            <td>
              <div class="input">
                <input type="text" readonly v-model="baseInfo.detail.sex">
              </div>
            </td>
            <th>填答者</th>
            <td>
              <div class="input">
                <input type="text" readonly v-model="baseInfo.detail.operator">
              </div>
            </td>
          </tr>
          <tr>
            <th>出生日期</th>
            <td>
              <div class="input">
                <input type="text" readonly v-model="baseInfo.detail.birthday">
              </div>
            </td>
            <th>与孩子关系</th>
            <td>
              <div class="input">
                <input type="text" readonly v-model="baseInfo.detail.relation">
              </div>
            </td>
          </tr>
          <tr>
            <th>实足年龄</th>
            <td>
              <div class="input">
                <input type="text" readonly v-model="baseInfo.age">
              </div>
            </td>
            <th>联络Email</th>
            <td>
              <div class="input">
                <input type="text" readonly v-model="baseInfo.detail.email">
              </div>
            </td>
          </tr>
          <tr>
            <th width="140px">就学状况</th>
            <td>
              <div class="input">
                <input type="text" v-model="baseInfo.detail.grade">
              </div>
            </td>
            <th>施测者</th>
            <td>
              <div class="input">
                <input type="text" readonly v-model="baseInfo.operator_name">
              </div>
            </td>
          </tr>
          <!--          2019/10/17 文档要求删除施测人 身份及联系方式-->
          <!--          <tr>-->
          <!--            <th>施测人身份</th>-->
          <!--            <td>-->
          <!--              <div class="input">-->
          <!--                <input type="text" readonly v-model="baseInfo.operator_company">-->
          <!--              </div>-->
          <!--            </td>-->
          <!--            <th>施测人联系方式</th>-->
          <!--            <td>-->
          <!--              <div class="input">-->
          <!--                <input type="text" readonly v-model="baseInfo.operator_mobile">-->
          <!--              </div>-->
          <!--            </td>-->
          <!--          </tr>-->
        </table>
        <p class="title3 tx-l">本检核表结果</p>
        <table border="1" cellspacing="1" cellpadding="0">
          <tr>
            <td>
              <p>
                {{scoreinfo}}
              </p>
            </td>
          </tr>
        </table>
        <!-- 群类说明 -->
        <p class="title3 tx-l">群类说明</p>
        <table border="1" cellspacing="1" cellpadding="0">
          <tr>
            <th>六大群类</th>
            <th>群类说明</th>
          </tr>
          <tr>
            <td>一、社会互动问题</td>
            <td>
              <p>评估儿童人际关系。</p>
            </td>

          </tr>
          <tr>
            <td>二、持续重复行为</td>
            <td>评估儿童重复性的游戏或刻板性的动作。</td>

          </tr>
          <tr>
            <td>三、体觉困扰</td>
            <td>评估儿童身体感觉的敏感度是否过于强烈或迟钝。</td>

          </tr>
          <tr>
            <td>四、非典型沟通及发展</td>
            <td>评估儿童语言、沟通与特殊能力的发展。</td>

          </tr>
          <tr>
            <td>五、情绪困扰</td>
            <td>评估儿童情感接收与表达问题。</td>

          </tr>
          <tr>
            <td>六、注意力与安全问题</td>
            <td>评估儿童选择性注意力及安全意识问题。</td>
          </tr>
        </table>
      </div>
      <div class="table" :class="{'pdfheight': isPrint}">
        <!-- 各群类得分百分比 -->
        <p class="title3 tx-l">各群类得分百分比</p>
        <table border="1" cellspacing="1" cellpadding="0">
          <tr>
            <th>六大群类</th>
            <th>群类得分百分比</th>
            <th>群类得分百分比侧面图</th>
          </tr>
          <tr v-for="(list, index) in cateScore" :key="index">
            <td>{{chnum[index] + '、' + list.cate_name}}</td>
            <td>
              <p style="text-align:center;">{{list.percent + '%'}}</p>
            </td>
            <td class="splTd">
              <span :style="{width:list.percent * 3 + 'px'}"></span>
              <span>{{list.percent + '%'}}</span>
            </td>
          </tr>
        </table>
        <!-- ◆	解释与建议 -->
        <p class="title3 tx-l">◆ 结果说明</p>
        <div class="tips">
          <h3>1.本检核表结果说明：</h3>
          <div style="padding-left: 18px;">
            <p v-if="score&&score>=15">儿童极可能有ASD，强烈建议寻求医疗专业人员诊断或特教专业人员鉴定，并积极安排相关介入协助。</p>
            <p v-if="score&&score>=11&&score<=14">儿童有ASD的可能性较高，建议转介医疗评估或特教专业咨询与鉴定。</p>
            <p v-if="score&&score>=8&&score<=10">儿童有ASD的可能性略高，建议再搜集其他资料进一步评估。</p>
            <p v-if="score&&score<=7">儿童有ASD的可能性低，不需再进一步评估。</p>
          </div>
          <br/>
          <h3>2.各群类得分百分比结果说明</h3>
          <div style="padding-left: 18px;">
            <p>
              本检核表内容检核自闭症类群障碍的六大行为特征，施测完可得出「群类得分百分比」，这些百分比呈现在上面的表里。从百分比可看出您孩子的行为符合每个群类的情形。提醒您，百分比高不一定表示孩子在该群类的问题严重程度就比较高，因为可能孩子过去有这些状况，但现在已经改善了，亦或孩子虽然目前有这些状况，但对他的日常生活影响不大。因此，不宜直接做跨群类问题严重度比较。建议您可以参考各群类得分百分比，以及孩子的日常生活功能受影响程度，来进一步理清您孩子的介入需求，并决定介入需求的优先级。
            </p>
          </div>
        </div>

        <div class="tips">
          <p v-if="score>=15" style="padding-left: 18px;margin-bottom: 10px;">
            以下依照您孩子的群类得分百分比，暂时排列您孩子的介入需求优先级。若有不同群类的得分百分比相同时，则依照上面表中的六大群类顺序排列。凡得分百分比低于50 %的群类在此不列出。</p>
          <p v-if="score>=15" v-for="(list ,index) in height" :key="index" style="margin-left:20px">
            <span>{{index+1}}. </span><span>{{list.cate_name}}</span>
          </p>
        </div>
      </div>
      <div class="table" :class="{'pdfheight': isPrint}">
        <div class="tips">
          <h3 v-if="score>=15">3.介入计划建议</h3>
          <h3 v-else>3.教育计划建议</h3>
          <p style="padding-left:18px;">
            根据填答者在本检核表所描绘孩子的行为特征，在此归纳出孩子过去或现在可能出现的问题，并给予相关介入或教学建议。以下建议是依照六大群类的顺序排列，治疗师可参考上方的介入优先级、孩子的其他相关数据以及日常生活功能受影响程度，选择对应的<span
            v-if="score>=15">介入计划</span><span v-else>教育计划</span>建议项目，提供父母参考，并与父母共同讨论拟定<span v-if="score>=15">介入计划</span><span
            v-else>教育计划</span>。
          </p>
          <div v-for="(list ,index) in suggest" :key="index" style="padding-left: 18px;" v-if="index<2">
            <p class="title3 tx-l">{{chnum1[index] + '' + list.cate_name}}</p>
            <table border="1" cellspacing="1" cellpadding="0">
              <tr>
                <th>问题描述</th>
                <th>建议</th>
              </tr>
              <tr v-for="(item,indexs) in list.suggest_item" :key="indexs">
                <td style="width: 30%">{{item.item}}</td>
                <td>
                  <p>{{item.suggest}}</p>
                </td>
              </tr>
            </table>
          </div>
          <!--          <div v-for="(list ,index) in suggest" :key="index" style="padding-left: 18px;">-->
          <!--            <h4>{{chnum1[index] + '' + list.cate_name}}</h4>-->
          <!--            <ul class="suggest-box">-->
          <!--              <li v-for="(item,indexs) in list.suggest_item" :key="indexs">{{item.suggest}}</li>-->
          <!--            </ul>-->
          <!--          </div>-->
        </div>
      </div>
      <div ref="table2" class="table" :class="{'pdfheight': isPrint}" v-if="suggest.length>2">
        <div class="tips">
          <div v-for="(list ,index) in suggest" :key="index" style="padding-left: 18px;" v-if="index>1">
            <p class="title3 tx-l">{{chnum1[index] + '' + list.cate_name}}</p>
            <table border="1" cellspacing="1" cellpadding="0">
              <tr>
                <th>问题描述</th>
                <th>建议</th>
              </tr>
              <tr v-for="(item,indexs) in list.suggest_item" :key="indexs">
                <td style="width: 30%">{{item.item}}</td>
                <td>
                  <p>{{item.suggest}}</p>
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>

      <!--      :class="{'pdfheight': isPrint}"-->
      <div class="table">
      <!--
        <p class="title3 tx-l">◆备注</p>
        <div class="tips" style="padding-left:0">
          <p style="text-indent: -1.2rem;margin-left: 1.2rem;">
            1. 不宜根据单一评估工具或信息来源进行解释或判断，使用本检核表时，宜结合家长与教师晤谈、医疗与学校纪录、临床观察或其他相关测验资料等作为参考，将有助于了解儿童的整体行为状况。
          </p>
          <p style="text-indent: -1.2rem;margin-left: 1.2rem;">2. 如果您对本测验结果或解释有任何疑问，或有想进一步了解的地方，请与施测人员沟通讨论。</p>
        </div>
        -->
        <p class="title3 tx-l">◆补充说明</p>
        <div class="tips" style="padding-left:0;">
          <p style="white-space: pre-wrap">{{baseInfo.remark}}</p>
        </div>
      </div>
      -->
    </div>
    
    <button class="print" v-on:click="printPdf">打印</button>
    <!--底部-->
    <footer-nav></footer-nav>
  </div>
</template>

<script>
    import HeaderNav from '@/components/HeaderNav'
    import FooterNav from '@/components/FooterNav'
    import echarts from 'echarts'

    export default {
        name: 'aboutus',
        data() {
            return {
                current: '',
                baseInfo: {},
                cateScore: [],
                scoreinfo: '', //得分结果描述
                score: '', //得分
                high: [],
                highest: [],
                height: '',
                suggest: [],
                isPrint: false,
                chnum: ['一', '二', '三', '四', '五', '六', '七', '八', '九', '十', '十一', '十二', '十三', '十四', '十五', '十六', '十七', '十八', '十九', '二十',],
                chnum1: ['①', '②', '③', '④', '⑤', '⑥', '⑦', '⑧', '⑨', '⑩', '⑫', '⑬', '⑭', '⑮', '⑯', '⑰', '⑱', '⑲', '⑳'],
            }
        },
        components: {
            HeaderNav,
            FooterNav,
            echarts
        },
        methods: {
            getData() {
                let params = {
                    exam_id: this.$route.query.eid
                }
                this.$post('report/getReport', params).then(res => {
                    console.log(res)
                    if (res.code === 1) {
                        this.suggest = res.data.suggest.data;
                        this.height = res.data.hight
                        this.baseInfo = res.data.base_info;
                        this.cateScore = res.data.cate_result.cate_score;
                        this.scoreinfo = res.data.cate_result.result;
                        this.score = res.data.cate_result.total_score
                        this.$nextTick(() => {
                            if (this.$route.query.print) {
                                this.printPdf()
                            }
                        })
                    } else {
                        this.$layer.msg(res.msg)
                    }
                }).catch(response => {
                    this.$layer.msg('网络异常，请稍后重试！')
                })
            },
            printPdf() {
                this.isPrint = true
                setTimeout(() => {
                    this.getPdf('自闭症类群障碍检核表（汉语版）', () => {
                        this.isPrint = false
                    })
                }, 100)
            }
        },
        created() {
            this.getData()
        },
    }
</script>

<style lang="less" scoped>
  @import url('../../assets/css/page/result');
</style>
